<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {};
</script>

<style>
body,
html {
  width: 100%;
  height: 100%;
  font-family: "Microsoft YaHei";
  font-size: var(--el-font-size-base);
}
* {
  margin: 0;
  padding: 0;
}
#app {
  min-width: 1400px;
  height: 100%;
  min-height: 900px;
}
</style>