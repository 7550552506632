import { createRouter, createWebHashHistory } from "vue-router";
// 登录页面
import loginView from "@/views/user/login";
// 注册页面
import registerView from "@/views/user/register";
// 默认页面
import indexView from "@/views/index/index";
const routes = [
  {
    path: "/",
    name: "index",
    component: indexView,
    children: [
      {
        path: "/",
        name: "Home",
        component: () => import("@/views/index/Home/Home"),
      },
      {
        path: "/product_List",
        name: "ProductList",
        component: () =>
          import("@/views/index/CommodityManagement/ProductList"),
      },
      {
        path: "/add_edit",
        name: "AddEdit",
        component: () => import("@/views/index/CommodityManagement/AddEdit"),
      },
      {
        path: "/order_list",
        name: "OrderList",
        component: () => import("@/views/index/OrderManagement/OrderList"),
      },
      {
        path: "/user_list",
        name: "UserList",
        component: () => import("@/views/index/UserManagement/List"),
      },
      {
        path: "/picture_list",
        name: "PictureList",
        component: () => import("@/views/index/PictureManagement/PictureList"),
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: loginView,
  },
  {
    path: "/register",
    name: "register",
    component: registerView,
  },
];
const router = createRouter({
  routes,
  history: createWebHashHistory(),
});
// router.beforeEach((to, from, next) => {
//   let isLogin = localStorage.getItem("user_token");
//   if (isLogin) {
//     next();
//   } else {
//     if (to.path == "/login") {
//       next();
//     } else {
//       next("/login");
//     }
//   }
// });
export default router;
