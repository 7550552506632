<template>
  <div class="home">
    <el-container style="height: 100%">
      <el-header style="background-color: #337ecc">
        <el-row align="middle" style="height: 100%">
          <el-col
            :span="2"
            :offset="22"
            style="
              display: flex;
              align-items: center;
              justify-content: space-around;
            "
          >
            <el-avatar :size="50" :src="circleUrl" />
            <div style="display: flex; align-items: center">
              <span>张林杰</span>
              <el-dropdown trigger="click">
                <el-icon style="cursor: pointer">
                  <ArrowDown />
                </el-icon>
                <template #dropdown>
                  <el-dropdown-menu>
                    <el-dropdown-item @click="loginOut()"
                      >退出登录</el-dropdown-item
                    >

                    <el-dropdown-item>更换头像</el-dropdown-item>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </div>
          </el-col>
        </el-row>
      </el-header>
      <el-container>
        <el-aside width="200px">
          <el-menu
            :default-active="defaultRouter"
            background-color="#18222c"
            class="el-menu-vertical-demo"
            text-color="#fff"
            :router="true"
            style="height: 100%"
          >
            <el-menu-item index="/">
              <el-icon :size="13"> <HomeFilled /> </el-icon>首页
            </el-menu-item>
            <el-sub-menu index="1">
              <template #title>
                <el-icon :size="13"> <ShoppingCart /> </el-icon>订单管理
              </template>
              <el-menu-item index="/order_list">订单列表</el-menu-item>
            </el-sub-menu>
            <el-sub-menu index="2">
              <template #title>
                <el-icon :size="13"> <UserFilled /> </el-icon>用户管理
              </template>
              <el-menu-item index="/user_list">用户列表</el-menu-item>
            </el-sub-menu>
            <el-sub-menu index="3">
              <template #title>
                <el-icon :size="13"> <ShoppingCartFull /> </el-icon>商品管理
              </template>
              <el-menu-item index="/product_list">商品列表</el-menu-item>
              <el-menu-item index="/add_edit">商品添加/编辑</el-menu-item>
            </el-sub-menu>
            <el-sub-menu index="4">
              <template #title>
                <el-icon :size="13"> <PictureFilled /> </el-icon>图片管理
              </template>
              <el-menu-item index="/picture_list">图片列表</el-menu-item>
            </el-sub-menu>
          </el-menu>
        </el-aside>
        <el-main style="box-sizing: border-box">
          <router-view></router-view>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import { ref, watch } from "vue";
import router from "@/routers/index";
import { logOut } from "@/api/api.js";
import { ElMessage } from "element-plus";
export default {
  name: "asideNav",
  setup() {
    let defaultRouter = ref("");
    let circleUrl = ref(
      "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png"
    );
    let defaultOpen = ref([]);
    // const logOut = () => {
    //   logout()
    //     .then((res) => {
    //       console.log(res);
    //       localStorage.removeItem("user_token");
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // };
    watch(
      () => router.currentRoute.value.path,
      (newPath, oldPath) => {
        console.log("新路由:" + newPath + ",旧路由:" + oldPath);
        defaultRouter.value = newPath;
        console.log(defaultRouter.value);
      },
      {
        immediate: true,
        deep: true,
      }
    );

    // 退出登录
    function loginOut() {
      console.log("logOut-1111");
      logOut()
        .then((res) => {
          if (res.code == 0) {
            router.push("/login");
          } else {
            ElMessage.error(res.message);
          }
        })
        .catch((error) => {
          console.log(error, 333);
        });
    }
    return {
      circleUrl,
      defaultRouter,
      defaultOpen,
      loginOut,
    };
  },
};
</script>

<style>
.home {
  width: 100%;
  height: 100%;
}
</style>
