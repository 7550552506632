import axios from "axios";
import { ElMessage } from "element-plus";
console.log(process.env.VUE_APP_BASE_API, 88889999);
const http = axios.create({
  // baseURL: "http://www.service.com",
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 8000,
  withCredentials: true,
});
http.interceptors.request.use(
  (config) => {
    let userToken = localStorage.getItem("user_token");
    console.log(userToken);
    config.headers = {
      Accept: ["application/json"], // 返回JSON 和 跨域 必须
      Authorization: userToken,
    };
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
http.interceptors.response.use(
  (response) => {
    let resPose = JSON.parse(response.data);
    if (resPose.code == 0) {
      return resPose;
    } else {
      ElMessage.error(resPose.message);
    }
  },
  (error) => {
    return Promise.reject(error);
  }
);
export default http;
