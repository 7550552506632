<template>
  <div class="login">
    <div class="from_container">
      <div class="login_switch">

        <span :style="{color:isUserLoginPage?'blue':'black'}" @click="isUserLoginPage = true">用户名登录</span>
        &emsp13;|&emsp13;
        <span :style="{color:isUserLoginPage?'black':'blue'}" @click="isUserLoginPage = false">手机验证登录</span>
      </div>
      <div class="login_from_input" v-if="isUserLoginPage">
        <div class="from_box">
          <label for="">用户名:</label>
          <el-input class="login_input" v-model="user_name" placeholder="请输入用户名" />
        </div>
        <div class="from_box">
          <label for="">密码:</label>
          <el-input class="login_input" v-model="password" placeholder="请输入密码" />
        </div>
        <div style="
            display: flex;
            margin-top: 20px;
            justify-content: space-between;
          ">
          <el-button type="primary" @click="loginButton" style="width: 47%">登录</el-button>
          <keep-alive>
            <router-link to="/register" style="width: 47%">
              <el-button type="warning" style="width: 100%">注册</el-button>
            </router-link>
          </keep-alive>
        </div>
      </div>
      <div class="login_from_input" v-else>

        <el-form :model="formData.phoneLoginData" status-icon :rules="rules" label-width="120px" class="demo-ruleForm">
          <el-form-item label="手机号">
            <el-row>
              <el-col :span="15">
                <el-input v-model="formData.phoneLoginData.tel" />
              </el-col>
              <el-col :span="3" :push="1">
                <el-button type="primary" @click="getTelCode()">获取验证码</el-button>
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item label="验证码">
            <el-row>
              <el-col :span="23">
                <el-input v-model="formData.phoneLoginData.code" />
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="telLoginFun()">登录</el-button>
            <router-link to="/register" style="padding-left:20px">
              <el-button type="warning">注册</el-button>
            </router-link>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import { login, telCode, telLogin } from "@/api/api.js";
import { reactive, ref } from "vue";
import router from "@/routers/index.js";
import { ElMessage } from 'element-plus'
export default {
  setup () {
    let user_name = ref("");
    let password = ref("");
    let isUserLoginPage = ref(true)
    const loginButton = () => {
      login({ account_number: user_name.value, user_pwd: password.value })
        .then((res) => {
          if (res.code == 0) {
            localStorage.setItem("user_token", res.data.token);
            router.push("/");
          } else {
            ElMessage.error(res.message)
            return;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };

    let formData = reactive({
      phoneLoginData: {
        tel: '',
        code: '',
        isReg: 1
      }
    })

    // 获取短信验证码
    function getTelCode () {
      telCode(formData.phoneLoginData).then((res) => {
        ElMessage.success(res.message)
      })
    }
    function telLoginFun () {
      telLogin(formData.phoneLoginData).then((res) => {
        if (res.code == 0) {
          ElMessage.success(res.message)
          localStorage.setItem("user_token", res.data.token);
          router.push("/");
        }
      })
    }
    return {
      user_name,
      loginButton,
      getTelCode,
      telLoginFun,
      password,
      formData,
      isUserLoginPage
    };
  },
};
</script>

<style scoped>
.login {
  min-width: 1400px;
  display: flex;
  background-image: url("@/assets/bg.png");
  justify-content: center;
  align-items: center;
  height: 100%;
  /* background-color: orange; */
}
.from_container {
  width: 500px;
  height: 400px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 0px 15px 3px black;
  padding: 15px;
  position: relative;
  /* background-color: rgba(0, 0, 0, 50%); */
}
.login_switch {
  /* padding: 10px; */
  text-align: center;
  font-size: 20px;
  box-sizing: border-box;
  margin-top: 50px;
}
.login_switch:hover {
  cursor: pointer;
}
.login_input {
  width: 400px;
}
.from_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}
.login_from_input {
  height: 240px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>