<template>
  <div class="register">
    <div class="register_container">
      <h3>注册</h3>
      <div class="input_group">
        <div class="input_group_item">
          <label class="input_label">手机号:</label>
          <div style="width: 300px">
            <el-input
              v-model="phone_number"
              placeholder="请输入手机号"
            ></el-input>
          </div>
        </div>
        <div class="input_group_item">
          <label class="input_label">验证码:</label>
          <div style="display: flex">
            <el-input
              v-model="phone_code"
              placeholder="请输入手机验证码"
              style="width: 150px"
            ></el-input>
            <el-button
              type="primary"
              style="margin-left: 20px"
              @click="send_code"
              :disabled="isCode === 0 ? true : false"
              >{{
                isCode === 0 ? `${countTime}秒之后` : phone_code_txt
              }}</el-button
            >
          </div>
        </div>
        <div class="input_group_item">
          <label class="input_label">用户名:</label>
          <div style="width: 300px">
            <el-input v-model="user_name" placeholder="请输入用户名"></el-input>
          </div>
        </div>
        <div class="input_group_item">
          <label class="input_label">用户邮箱:</label>
          <div style="width: 300px">
            <el-input v-model="user_email" placeholder="请输入邮箱"></el-input>
          </div>
        </div>
        <div class="input_group_item">
          <label class="input_label">密码:</label>
          <div style="width: 300px">
            <el-input v-model="password" placeholder="请输入密码"></el-input>
          </div>
        </div>
        <div class="input_group_item">
          <label class="input_label">确认密码:</label>
          <div style="width: 300px">
            <el-input
              v-model="confirm_password"
              placeholder="两次密码必须一致"
            ></el-input>
          </div>
        </div>
      </div>
      <el-button type="primary" class="register_button" @click="registerSubmit"
        >注册</el-button
      >
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import router from "@/routers/index.js";
import { register, send_phone_code } from "@/api/api";
export default {
  setup() {
    let countdown = ref(null);
    let isCode = ref("");
    let phone_code_txt = ref("发送验证码");
    let countTime = ref(60);
    let phone_number = ref("");
    let phone_code = ref("");
    let user_name = ref("");
    let password = ref("");
    let confirm_password = ref("");
    let user_email = ref("");
    const phoneReg = /^1[0-9]{10}/;
    const usernameReg = /[a-zA-Z0-9]/;
    const emailReg = /[\w]{6,11}[@.\w]$/;
    const send_code = () => {
      if (phone_number.value == "") {
        return ElMessage.error("手机号不能为空");
      }
      send_phone_code({ tel: phone_number.value, isReg: 0 })
        .then((res) => {
          console.log(res);
          if (res.code == 0) {
            isCode.value = res.code;
            console.log(isCode.value);
            countdown = setInterval(() => {
              countTime.value -= 1;
              if (countTime.value < 10) {
                countTime.value = "0" + countTime.value;
                if (countTime.value <= 0) {
                  clearInterval(countdown);
                  isCode.value = 1;
                  phone_code_txt.value = "重新发送";
                }
              }
            }, 1000);
          } else {
            phone_code_txt.value = "重新发送";
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };
    const registerSubmit = () => {
      if (!phoneReg.test(phone_number.value)) {
        return alert("手机号码格式不正确");
      }
      if (!usernameReg.test(user_name.value)) {
        return alert("用户名不可以使用中文");
      }
      if (password.value !== confirm_password.value) {
        return alert("两次密码不一致，请重新输入");
      }
      register({
        tel: phone_number.value,
        code: phone_code.value,
        account_number: user_name.value,
        user_pwd: password.value,
      })
        .then((res) => {
          router.push("/login");
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    return {
      user_email,
      confirm_password,
      phone_number,
      user_name,
      password,
      registerSubmit,
      phone_code,
      send_code,
      isCode,
      countTime,
      phone_code_txt,
    };
  },
};
</script>

<style>
.register {
  min-width: 1400px;
  height: 100%;
  min-height: 900px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
}
.register_container {
  background-color: white;
  border-radius: 15px;
  width: 500px;
  height: 400px;
  box-shadow: 0 0 5px 5px #fff8dc;
  box-sizing: border-box;
  padding: 15px;
}
h3 {
  text-align: center;
  font-family: "微软雅黑";
}
.input_group_item {
  display: flex;
  margin-top: 20px;
}
.register_button {
  width: 100%;
  margin-top: 20px;
}
.input_label {
  width: 100px;
}
</style>