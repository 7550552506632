import axios from "./http.js";
// 用户注册
export const register = (data) => {
  console.log(data);
  return axios({
    url: "/login/registerAdd",
    method: "post",
    data,
  });
};
// 用户登录
export const login = (data) => {
  console.log(data);
  return axios({
    method: "post",
    url: "/login/accountlogin",
    data,
  });
};
// 获取短信验证码
export const telCode = (data) => {
  console.log(data);
  return axios({
    method: "post",
    url: "/login/telCode",
    data,
  });
};
// 短信验证码登录
export const telLogin = (data) => {
  console.log(data);
  return axios({
    method: "post",
    url: "/login/telLogin",
    data,
  });
};
// 退出登录
export const logOut = (data) => {
  console.log(data);
  return axios({
    method: "post",
    url: "/login/logOut",
    data,
  });
};
// 获取商品列表
export const getProductList = (data) => {
  console.log(data);
  return axios({
    method: "post",
    url: "/product/product/list",
    data,
  });
};
// 商品添加/编辑
export const productAddEdit = (data) => {
  console.log(data);
  return axios({
    method: "post",
    url: "/product/updateProduct",
    data,
  });
};
// 商品删除
export const productDelete = (data) => {
  console.log(data);
  return axios({
    method: "get",
    url: `/product/product/delete/${data}`,
  });
};
// 获取订单列表
export const getOrdersList = (data) => {
  console.log(data);
  return axios({
    method: "get",
    url: "/orders/list",
    query: data,
  });
};
// 商户接单
export const OrdersReceive = (data) => {
  console.log(data);
  return axios({
    method: "post",
    url: "/orders/receive",
    data,
  });
};
// 图片列表
export const getPictureList = (data) => {
  console.log(data);
  return axios({
    method: "post",
    url: "/images/list",
    data,
  });
};
export const deleteImg = (data) => {
  console.log(data);
  return axios({
    method: "post",
    url: "/images/delete",
    data,
  });
};
// 发送手机验证码
export const send_phone_code = (data) => {
  console.log(data);
  return axios({
    method: "post",
    url: "/login/telCode",
    data,
  });
};
